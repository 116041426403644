<template>
  <div class="cuadro_central mt-4 shadow">
    <div id="principal" class="mt-4 principal2">
      <div class="accordion mb-2 " id="accordionAnyadir">
        <div>
          <b-button
            class=" btn-ok mb-3"
            type="button"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
            :disabled="disabledCrear"
            @click="buttonNuevoAnnotation()"
          >
            <i class="fas fa-plus-circle"></i> New Annotation
          </b-button>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionAnyadir"
          >
            <div class="card-body principal shadow rounded bg-light">
              <b-form @submit.prevent="procesarFormulario">
                <b-row>
                  <b-col sm="1">
                    <label for="rsid" class="form-label"
                      ><strong>Rsid</strong></label
                    >
                    <b-form-input
                      class="form-control mb-4"
                      size="sm"
                      v-model="rsid"
                      id="rsid"
                      rows="1"
                    ></b-form-input>
                  </b-col>
                </b-row>

                <strong>Variants</strong>
                <div
                  id="formLine"
                  style="font-size:75%"
                  class="shadow-sm rounded border border-info container-fluid mb-4 mt-2 bg-white"
                >
                  <b-row class="mb-2 mt-2">
                    <b-col>
                      <label for="gene" class="form-label">Gene</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="gene"
                        id="gene"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="chr" class="form-label">Chr</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="chr"
                        id="chr"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="start38" class="form-label">Start 38</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="start38"
                        id="start38"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="end38" class="form-label">End 38</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="end38"
                        id="end38"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="a1EffectAllele" class="form-label"
                        >A1 Effect Allele</label
                      >
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="a1EffectAllele"
                        id="a1EffectAllele"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="a2" class="form-label">A2</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="a2"
                        id="a2"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="popFreqEffectAllele" class="form-label"
                        >PopFreqEffectAllele(NCBI)</label
                      >
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="popFreqEffectAllele"
                        id="popFreqEffectAllele"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="hgvs" class="form-label">Hgvs</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="hgvs"
                        id="hgvs"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                  </b-row>

                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <b-row class="mb-2 mt-2">
                      <b-col>
                        <b-button
                          variant="primary"
                          class="btn btn-ok"
                          size="sm"
                          @click="addLineVariant()"
                          >Add</b-button
                        >
                      </b-col>
                      <b-col>
                        <b-button
                          size="sm"
                          variant="outline-danger"
                          @click="deleteLineVariant()"
                          class="btn-cancel"
                          ><i class="fas fa-trash-alt"></i
                        ></b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <b-table
                    class="ml-1 "
                    head-variant="light"
                    id="tableVariant"
                    :fields="tableVariantFields"
                    :items="tableVariant"
                    bordered
                  >
                  </b-table>
                </div>

                <strong>Effects</strong>
                <div
                  id="formLine"
                  style="font-size:75%"
                  class="shadow-sm rounded border border-info container-fluid mb-4 mt-2 bg-white"
                >
                  <b-row class="mb-2 mt-2">
                    <b-col>
                      <label for="effect" class="form-label">Effect</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="effect"
                        id="effect"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="beta" class="form-label">Beta</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="beta"
                        id="beta"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="or" class="form-label">OR</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="or"
                        id="or"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="pvalor" class="form-label">p-valor</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="pvalor"
                        id="pvalor"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="studySize" class="form-label"
                        >Study Size</label
                      >
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="studySize"
                        id="studySize"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="sex" class="form-label">Sex</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="sex"
                        id="sex"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="annotation" class="form-label"
                        >Annotation</label
                      >
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="annotation"
                        id="annotation"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <label for="eaf" class="form-label">EAF</label>
                      <b-form-input
                        class="form-control"
                        size="sm"
                        v-model="eaf"
                        id="eaf"
                        rows="1"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col cols="3">
                      <label for="sTrait" class="form-label ml-3">Trait</label>
                      <div class="col-sm">
                        <b-form-select v-model="sTrait" size="sm">
                          <option
                            v-for="f in traitMaster"
                            :value="f"
                            :key="f.id"
                            >{{ f.name }}</option
                          >
                        </b-form-select>
                      </div>
                    </b-col>
                    <b-col>
                      <strong>SubTraits</strong>
                      <div
                        id="formLine"
                        style="font-size:75%"
                        class="shadow-sm rounded border border-info container-fluid mb-4 mt-2 bg-white"
                      >
                        <b-row class="mb-2 mt-2">
                          <b-col cols="7">
                            <label for="nameSubtrait" class="form-label"
                              >Name</label
                            >
                            <b-form-input
                              class="form-control"
                              size="sm"
                              v-model="nameSubtrait"
                              id="nameSubtrait"
                              rows="1"
                            ></b-form-input>
                          </b-col>
                          <b-col>
                            <div
                              class="d-grid gap-2 d-md-flex justify-content-md-end"
                            >
                              <b-row class="mb-1 mt-3">
                                <b-col>
                                  <b-button
                                    variant="success"
                                    class="btn "
                                    size="sm"
                                    @click="addLineSubtrait()"
                                    ><i class="fas fa-plus-circle"></i
                                  ></b-button>
                                </b-col>
                                <b-col>
                                  <b-button
                                    size="sm"
                                    variant="danger"
                                    @click="deleteLineSubtrait()"
                                    ><i class="fas fa-minus-circle"></i
                                  ></b-button>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                        <b-table
                          class="ml-1 "
                          id="tableVariant"
                          :fields="tableSubtraitFields"
                          :items="tableSubtrait"
                          bordered
                        >
                        </b-table>
                      </div>
                    </b-col>
                    <b-col>
                      <strong>References</strong>
                      <div
                        id="formLine"
                        style="font-size:75%"
                        class="shadow-sm rounded border border-info container-fluid mb-4 mt-2 bg-white"
                      >
                        <b-row class="mb-2 mt-2">
                          <b-col>
                            <label for="pmid" class="form-label">PMID</label>
                            <b-form-input
                              class="form-control"
                              size="sm"
                              v-model="pmid"
                              id="pmid"
                              rows="1"
                            ></b-form-input>
                          </b-col>
                          <b-col>
                            <label for="paperRef" class="form-label"
                              >Paper Ref.</label
                            >
                            <b-form-input
                              class="form-control"
                              size="sm"
                              v-model="paperRef"
                              id="paperRef"
                              rows="1"
                            ></b-form-input>
                          </b-col>
                          <b-col>
                            <div
                              class="d-grid gap-2 d-md-flex justify-content-md-end"
                            >
                              <b-row class="mb-1 mt-3">
                                <b-col>
                                  <b-button
                                    variant="success"
                                    class="btn "
                                    size="sm"
                                    @click="addLineRef()"
                                    ><i class="fas fa-plus-circle"></i
                                  ></b-button>
                                </b-col>
                                <b-col>
                                  <b-button
                                    size="sm"
                                    variant="danger"
                                    @click="deleteLineRef()"
                                    ><i class="fas fa-minus-circle"></i
                                  ></b-button>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-row>
                        <b-table
                          class="ml-1 "
                          id="tableVariant"
                          :fields="tableRefFields"
                          :items="tableRef"
                          bordered
                        >
                        </b-table>
                      </div>
                    </b-col>
                  </b-row>
                  <b-col>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                      <b-row class="mb-2 mt-2">
                        <b-col>
                          <b-button
                            variant="primary"
                            class="btn btn-ok"
                            size="sm"
                            @click="addLineEffect()"
                            >Add</b-button
                          >
                        </b-col>
                        <b-col>
                          <b-button
                            size="sm"
                            variant="outline-danger"
                            @click="deleteLineEffect()"
                            class="btn-cancel"
                            ><i class="fas fa-trash-alt"></i
                          ></b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>

                  <b-table
                    class="ml-1 "
                    head-variant="light"
                    id="tableEffect"
                    :fields="tableEffectFields"
                    :items="tableEffect"
                    bordered
                  >
                    <template #cell(references)="row" align="center">
                      <b-table
                        class="ml-1 tablalinea"
                        id="tableRef"
                        small
                        :fields="tableRefFields"
                        :items="row.item.references"
                        bordered
                      >
                        <template #head(pmid)="data">
                          <span class="text-info">{{ data.label }}</span>
                        </template>
                        <template #head(paper_ref)="data">
                          <span class="text-info">{{ data.label }}</span>
                        </template>
                      </b-table>
                    </template>
                    <template #cell(trait)="row" align="center">
                      <p>{{ row.item.trait.name }}</p>
                      <b-table
                        id="tableSubtrait"
                        small
                        :fields="tableSubtraitFields"
                        :items="row.item.trait.subtraits"
                      >
                        <template #head(pmid)="data">
                          <span class="text-info">{{ data.label }}</span>
                        </template>
                        <template #head(paper_ref)="data">
                          <span class="text-info">{{ data.label }}</span>
                        </template>
                      </b-table>
                    </template>
                  </b-table>
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <b-button
                    variant="outline-danger"
                    size="sm"
                    class="sm mb-1 btn-cancel"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    @click="resetUpdate()"
                    ><i class="far fa-times-circle mr-1"></i>Cancel</b-button
                  >
                  <b-button
                    variant="primary"
                    size="sm"
                    type="submit"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    class="sm mb-1 btn-ok"
                    ><i class="far fa-check-circle mr-1"></i
                    >{{ txtBtnFormulario }}</b-button
                  >
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-auto" v-if="disabledPie">
        <b-input-group class="mt-1">
          <b-form-input
            v-model="filter"
            placeholder="Search..."
            class="ml-1 mr-1 shadow-sm"
            type="search"
          ></b-form-input>
          <b-pagination
            class="ml-2 mr-2 shadow-sm"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="tablaResultados"
          ></b-pagination>
          <b-form @submit.prevent="getAnnotation">
            <b-button
              variant="primary"
              type="submit"
              class="btn btn-primary  mb-1 mr-1   shadow-sm"
              ><i class="fas fa-sync"></i
            ></b-button>
          </b-form>
        </b-input-group>
        <b-table
          class="shadow-sm align-middle"
          head-variant="light"
          style="font-size:70%;vertical-align:middle;"
          id="tablaResultados"
          :busy="busy"
          :fields="camposTabla"
          :filter="filter"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          small
          fixed
          hover
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(variants)="row" align="center">
            <b-table
              id="tableRef"
              small
              :fields="tableVariantFields"
              :items="row.item.variants"
            >
            </b-table>
          </template>
          <template #cell(effects)="row" align="center">
            <b-table
              id="tableEffectsGeneral"
              small
              :fields="tableEffectFields"
              :items="row.item.effects"
              bordered
            >
              <template #cell(annotation)="row" align="center">
                <b-button
                  class="btn-line"
                  v-b-tooltip.hover
                  style="font-size:100%"
                  v-bind:title="row.item.annotation"
                >
                  Hover to show
                </b-button>
              </template>

              <template #cell(references)="row" align="center">
                <b-table
                  id="tableRef"
                  small
                  :fields="tableRefFields"
                  :items="row.item.references"
                >
                  <template #head(pmid)="data">
                    <span class="text-info">{{ data.label }}</span>
                  </template>
                  <template #head(paper_ref)="data">
                    <span class="text-info">{{ data.label }}</span>
                  </template>
                </b-table>
              </template>
              <template #cell(trait)="row" align="center">
                <p>{{ row.item.trait.name }}</p>
                <b-table
                  id="tableSubtrait"
                  small
                  :fields="tableSubtraitFields"
                  :items="row.item.trait.subtraits"
                >
                  <template #head(pmid)="data">
                    <span class="text-info">{{ data.label }}</span>
                  </template>
                  <template #head(paper_ref)="data">
                    <span class="text-info">{{ data.label }}</span>
                  </template>
                </b-table>
              </template>
            </b-table>
          </template>

          <template #cell(accion)="row" align="center">
            <b-button-group class="col-md-5" align="center" size="sm">
              <b-button
                size="sm"
                variant="outline-info"
                @click="row.toggleDetails"
                class="mr-1"
                ><i class="fas fa-info"></i>
                {{ row.detailsShowing ? '' : '' }}
              </b-button>
              <b-button
                size="sm"
                variant="outline-success"
                data-toggle="collapse"
                :disabled="disabledEditar"
                data-target="#collapseTwo"
                @click="buttonEditarAnnotation(row.item)"
                class="mr-1"
                ><i class="fas fa-edit"></i
              ></b-button>
              <b-button
                size="sm"
                variant="outline-danger"
                @click="deleteAnnotation(row.item)"
                class="mr-1"
                ><i class="fas fa-trash-alt"></i
              ></b-button>
            </b-button-group>
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only size="lg">
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Annotation',
  data() {
    return {
      editando: false,
      disabledCrear: false,
      filter: '',
      busy: false,
      txtBtnFormulario: 'Save',
      perPage: 10,
      currentPage: 1,
      disabledPie: true,
      disabledEditar: false,
      traitMaster: [],

      // Principal
      id: '',
      rsid: '',
      // Variante
      gene: '',
      chr: '',
      start38: '',
      end38: '',
      a1EffectAllele: '',
      a2: '',
      popFreqEffectAllele: '',
      hgvs: '',

      // Efecto
      effect: '',
      beta: '',
      or: '',
      pvalor: '',
      studySize: '',
      sex: '',
      annotation: '',
      eaf: '',

      // Trait y ref
      sTrait: '',
      nameSubtrait: '',
      pmid: '',
      paperRef: '',

      /////////////////////////////
      tableRef: [],
      tableRefFields: [
        { key: 'pmid', label: 'PMID', sortable: true },
        { key: 'paper_ref', label: 'Paper Ref', sortable: true }
      ],

      /////////////////////////////
      tableSubtrait: [],
      tableSubtraitFields: [{ key: 'name', label: 'Subtrait', sortable: true }],

      /////////////////////////////
      tableVariant: [],
      tableVariantFields: [
        { key: 'gene', label: 'Gene', sortable: true },
        { key: 'chr', label: 'Chr', sortable: true },
        { key: 'start38', label: 'Start38', sortable: true },
        { key: 'end38', label: 'End38', sortable: true },
        { key: 'a1', label: 'A1EffectAllele', sortable: true },
        { key: 'a2', label: 'A2', sortable: true },
        {
          key: 'pfea',
          label: 'PopFreqEffectAllele',
          sortable: true
        },
        { key: 'hgvs', label: 'Hgvs', sortable: true }
      ],
      /////////////////////////////
      tableEffect: [],
      tableEffectFields: [
        {
          key: 'effect',
          label: 'Effect',
          sortable: true
        },
        {
          key: 'beta',
          label: 'Beta',
          sortable: true
        },
        { key: 'or', label: 'OR', sortable: true },
        {
          key: 'pvalor',
          label: 'p-valor',
          sortable: true
        },
        {
          key: 'study_size',
          label: 'Study Size',
          sortable: true
        },
        {
          key: 'sex',
          label: 'Sex',
          sortable: true
        },
        {
          key: 'annotation',
          label: 'Annotation',
          sortable: true
        },
        {
          key: 'eaf',
          label: 'EAF',
          sortable: true
        },
        {
          key: 'references',
          label: 'Refs',
          sortable: true
        },
        {
          key: 'trait',
          label: 'Trait',
          sortable: true
        }
      ],
      camposTabla: [
        {
          key: 'rsid',
          label: 'Rsid',
          sortable: true,
          thStyle: { width: '140px' }
        },
        {
          key: 'variants',
          label: 'Variant',
          sortable: true,
          thStyle: { width: '460px' }
        },

        {
          key: 'effects',
          label: 'Effects',
          sortable: true,
          thStyle: { width: '880px' }
        },
        { key: 'accion', label: '', thStyle: { width: '120px' } }
      ],
      items: [],
      msgValue: '',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    };
  },
  methods: {
    obtenerTraitMaster() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .get('https://tool.mendelsbrain.com:60010/api/mendels/traitmaster', {
          headers: { Authorization: 'Bearer ' + user.accessToken }
        })
        .then(
          response => {
            this.traitMaster = response.data;
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    procesarFormulario() {
      if (!this.editando) {
        this.sendAnnotation();
      } else {
        this.updateAnnotation();
      }
      this.resetUpdate();
    },
    sendAnnotation() {
      let user = JSON.parse(localStorage.getItem('user'));

      axios
        .post(
          'https://tool.mendelsbrain.com:60010/api/mendels/annotation',
          {
            rsid: this.rsid,
            variants: this.tableVariant,
            effects: this.tableEffect
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(response => {
          this.showMsgBoxConfirm(
            response.data.message,
            'success',
            'Confirmation'
          );
        })
        .catch(error => {
          this.showMsgBoxConfirm(
            error.response.data.message +
              ' - Debug: ' +
              error.response.data.debugMessage,
            'danger',
            'Error'
          );
        });
    },
    updateAnnotation() {
      let user = JSON.parse(localStorage.getItem('user'));

      axios
        .put(
          'https://tool.mendelsbrain.com:60010/api/mendels/annotation',
          {
            id: this.id,
            rsid: this.rsid,
            variants: this.tableVariant,
            effects: this.tableEffect
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(response => {
          this.showMsgBoxConfirm(
            response.data.message,
            'success',
            'Confirmation'
          );
        })
        .catch(error => {
          this.showMsgBoxConfirm(
            error.response.data.message +
              ' - Debug: ' +
              error.response.data.debugMessage,
            'danger',
            'Error'
          );
        });
    },
    getAnnotation() {
      this.busy = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .get('https://tool.mendelsbrain.com:60010/api/mendels/annotation', {
          headers: {
            Authorization: 'Bearer ' + user.accessToken
          }
        })
        .then(
          response => {
            this.items = response.data;
            this.busy = false;
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    deleteAnnotation(item) {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .delete(
          'https://tool.mendelsbrain.com:60010/api/mendels/annotation/' +
            item.id,
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(response => {
          this.showMsgBoxConfirm(
            response.data.message,
            'success',
            'Confirmation'
          );
        })
        .then(error => {
          console.log(error);
        });

      this.resetUpdate();
      this.getAnnotation();
    },
    addLineSubtrait() {
      this.tableSubtrait.push({
        name: this.nameSubtrait
      });
      this.nameSubtrait = '';
    },
    deleteLineSubtrait() {
      this.tableSubtrait.splice(this.tableSubtrait.length - 1, 1);
    },
    addLineRef() {
      this.tableRef.push({
        pmid: this.pmid,
        paper_ref: this.paperRef
      });
      this.pmid = '';
      this.paperRef = '';
    },
    deleteLineRef() {
      this.tableRef.splice(this.tableRef.length - 1, 1);
    },
    addLineVariant() {
      this.tableVariant.push({
        gene: this.gene,
        chr: this.chr,
        start38: this.start38,
        end38: this.end38,
        a1: this.a1EffectAllele,
        a2: this.a2,
        pfea: this.popFreqEffectAllele,
        hgvs: this.hgvs
      });
      this.gene = '';
      this.chr = '';
      this.start38 = '';
      this.end38 = '';
      this.a1EffectAllele = '';
      this.a2 = '';
      this.popFreqEffectAllele = '';
      this.hgvs = '';
    },
    deleteLineVariant() {
      this.tableVariant.splice(this.tableVariant.length - 1, 1);
    },
    addLineEffect() {
      this.tableEffect.push({
        effect: this.effect,
        beta: this.beta,
        or: this.or,
        pvalor: this.pvalor,
        study_size: this.studySize,
        sex: this.sex,
        annotation: this.annotation,
        eaf: this.eaf,
        references: this.tableRef,
        trait: {
          name: this.sTrait.name,
          idTraitMaster: this.sTrait.id,
          subtraits: this.tableSubtrait
        }
      });
      this.effect = '';
      this.beta = '';
      this.or = '';
      this.pvalor = '';
      this.studySize = '';
      this.sex = '';
      this.annotation = '';
      this.eaf = '';
      this.tableRef = [];
      this.tableSubtrait = [];
    },
    deleteLineEffect() {
      this.tableEffect.splice(this.tableEffect.length - 1, 1);
    },
    resetInfoModal() {
      this.infoModal.title = '';
      this.infoModal.content = '';
    },
    showMsgBoxConfirm(msg, vari, title) {
      this.$bvModal
        .msgBoxOk(msg, {
          title: title,
          size: 'lg',
          buttonSize: 'sm',
          okVariant: vari,
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          this.msgValue = value;
          this.getAnnotation();
        });
    },
    resetUpdate() {
      (this.id = ''),
        (this.rsid = ''),
        (this.gene = ''),
        (this.chr = ''),
        (this.start38 = ''),
        (this.end38 = ''),
        (this.a1EffectAllele = ''),
        (this.a2 = ''),
        (this.popFreqEffectAllele = ''),
        (this.hgvs = ''),
        (this.tableEffect = []),
        (this.tableRef = []),
        (this.tableSubtrait = []),
        (this.tableVariant = []),
        (this.sTrait = ''),
        (this.nameSubtrait = ''),
        (this.pmid = ''),
        (this.paperRef = '');

      this.disabledEditar = false;
      this.disabledCrear = false;

      this.getAnnotation();
    },
    buttonNuevoAnnotation() {
      this.disabledEditar = !this.disabledEditar;
      this.txtBtnFormulario = 'Save';
      this.editando = false;
    },
    buttonEditarAnnotation(item) {
      this.disabledCrear = !this.disabledCrear;
      this.txtBtnFormulario = 'Update';
      this.editando = true;
      this.id = item.id;
      this.rsid = item.rsid;
      this.tableVariant = item.variants;
      this.tableEffect = item.effects;

      this.items = [];
    }
  },
  computed: {
    rows() {
      return this.items.length;
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.getAnnotation();
    this.obtenerTraitMaster();
  },
  components: {}
};
</script>
<style scoped>
.btn-ok {
  border-radius: 16px;
  border-style: solid;
  border-width: thin;
  padding: 6px 15px 6px 15px;

  color: #8caf36;
  border-color: #8caf36;
  background-color: #ffffff;
}
.btn-line {
  border-radius: 2px;
  border-style: solid;
  border-width: thin;
  padding: 2px 2px 2px 2px;

  color: #888888;
  border-color: #888888;
  background-color: #ffffff;
}
.btn-ok:hover {
  border-radius: 16px;
  border-style: solid;
  border-width: thin;
  padding: 6px 15px 6px 15px;

  color: #ffffff;
  border-color: #8caf36;
  background-color: #8caf36;
}
.btn-cancel {
  border-radius: 16px;
  border-style: solid;
  border-width: thin;
  padding: 6px 15px 6px 15px;

  color: #cb3234;
  border-color: #cb3234;
  background-color: #ffffff;
}
.btn-cancel:hover {
  border-radius: 16px;
  border-style: solid;
  border-width: thin;
  padding: 6px 15px 6px 15px;

  color: #ffffff;
  border-color: #cb3234;
  background-color: #cb3234;
}
.cuadro_central {
  background-color: #ffffff;
  padding: 20px 30px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 20px;
  -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.nav-link {
  color: #aaaaaa;
  text-align: center;
}
.image-logo {
  text-align: center;
}
.nav {
  margin-top: 50px;
  width: 1700px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaaaaa;
  line-height: 1em;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}
.parrafo {
  margin-top: 50px;
  width: 700px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaaaaa;
  line-height: 1em;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}
.tablalinea {
  height: 50%;
}
.principal {
  background: rgb(0, 0, 0);
}
</style>
